import { Link, useLoaderData } from "@remix-run/react";
import { LoaderFunction, redirect } from "@remix-run/node";

export const loader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  if (url.pathname === "/") {
    return redirect("/login");
  }

  const randomInt = Math.floor(Math.random() * 100);
  return { eventId: randomInt };
};

export default function Index() {
  const { eventId } = useLoaderData<typeof loader>();

  return (
    <div className="bg-indigo-700">
      <div className="mx-auto max-w-2xl px-4 py-16 text-center sm:px-6 sm:py-20 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">Welcome to Eventur 2.0</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-indigo-200">
          You can design a fancy registration form for the super special event{" "}
          {eventId}. It's special because we randomly generated that id just for
          you!
        </p>
        <Link
          to={`events/${eventId}/registration/designer`}
          className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-50 sm:w-auto"
        >
          Open Designer
        </Link>
      </div>
    </div>
  );
}
